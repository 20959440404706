@import "./bulma-config";
@import "../../node_modules/bulma/bulma";
@import "./fonts";

.is-family-secondary {
  font-family: $family-secondary;
  letter-spacing: 3px;
}

.app {
  min-height: 100vh;
  max-width: 100%;
  overflow: hidden;
}

.app-bg {
  background-image: linear-gradient(
    -180deg,
    $gradient-top 0%,
    $gradient-bottom 90%
  );
  padding-top: 15px;
  position: relative;
}

.no-margin {
  margin: 0 !important;
}

.left-sidebar {
  display: flex;
  padding-bottom: 90px;
}

.side-components-wrapper {
  display: flex;
  flex-direction: column;
}

.app-logo {
  order: 0;
}

.container-bg {
  min-height: 80vh;
}

.right-column {
  padding-left: 0;
  padding-right: 0;
}

strong {
  color: inherit;
}

.has-line-height-3 {
  line-height: 3rem;
}

.button {
  @include button-text;
  background: #2c2c2c;
  border-radius: 18px;
  border: none;
  height: auto;
  padding: 12px 24px;
  max-width: 100%;
  white-space: normal;
}

// Custom styles for react-select dropdown

.react-select__control,
.react-select__menu {
  border: none !important;
  width: 50% !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  border-radius: 0px !important;
}

.react-select__control {
  background-color: transparent !important;
  border-bottom: 1px solid #979797 !important;
}

.react-select__value-container {
  padding-left: 0 !important;
}

.react-select__menu {
  background-color: $grey-darker !important;
}

.react-select__single-value {
  color: $blue-input !important;
  margin-left: 0 !important;
}

.react-select__indicator-separator {
  display: none;
}

.is-size-12 {
  font-size: 12px;
}

.is-size-13 {
  font-size: 13px;
}

.is-size-14 {
  font-size: 14px;
}

.is-size-15 {
  font-size: 15px;
}

.is-size-16 {
  font-size: 16px;
}

@media (min-width: 900px) {
  .is-inline-block-small-desktop {
    display: inline-block !important;
  }

  .is-hidden-small-desktop {
    display: none;
  }
}

.chart-container {
  padding-right: 3rem;
}

.axis-label-container {
  margin-top: auto;
}

.thick-underline {
  border-bottom: 2px solid $grey-light;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 2rem;
}

.mt-6 {
  margin-top: 3rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.3rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.ml-5 {
  margin-left: 2rem;
}

.ml-6 {
  margin-left: 2.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.my-5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.m-auto {
  margin: auto;
}

.pt-4 {
  padding-top: 1.5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.w-3 {
  width: 34%;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.v-middle {
  vertical-align: middle;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.flex-direction-column {
  flex-direction: column;
}

.header {
  margin-bottom: 20px;
}

html {
  font-size: 14px;
}

.footer-bg {
  position: relative;
  height: 10vh;
}

.footer-container {
  height: 70px;
  padding-top: 22px;
  position: absolute;
  top: 50%;
  margin-top: -35px;
  left: 2.5%;
  width: 95%;
}

.crunchable-survey-button {
  background-color: $crunchable-violet;
  color: $white;
  border: 2px solid $white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  display: block;
}

.crunchable-survey-button:hover {
  background-color: $white;
  color: $crunchable-violet;
}

.header-bg {
  background-color: $crunchable-violet;
}

.header-container {
  height: 100px;
  color: $white;
}

.header-logo {
  margin-top: 20px;
}

.crunchable-description {
  max-width: 700px;
}

.crunchable-cta {
  margin-top: 10px;
}

.header-container h1 {
  font-size: 1.2rem;
  font-weight: bold;
}

.header-container p {
  font-size: 1rem;
  max-width: 600px;
}

.hover-pointer:hover {
  cursor: pointer;
}

.logo {
  margin-left: 22px;
}

.advice-wrapper {
  margin-left: -999px;
  padding: 20px;
  padding-left: 999px;
  border-radius: 15px;
  order: 2;
  flex-grow: 1;
}

.fade-in {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.no-select {
  user-select: none;
}

.export-button {
  border-radius: 7px;
  padding: 5px 14px;
  margin-top: 7px;
  z-index: 0;
  padding-left: 20px;
  margin-left: -13px;
}

.export-button-container {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  width: 208px;
  background-color: $white;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  &:hover {
    .export-button {
      color: white;
      text-decoration: underline;
    }
  }
}

.export-button-wrapper {
  position: absolute;
  top: -20px;
}

// Crunchable stuff

.crunchable-info {
  display: block;
  margin-top: 20px;
  padding: 10px;
  color: black;

  p {
    display: inline;
  }
}

.has-bg-violet {
  background-color: $violet;
  color: $white;
}

ul {
  text-align: left;
  color: black;
  position: relative;
  margin-top: 15px;
  li {
    padding-left: 30px;
    margin-bottom: 10px;
    &.export-bmc {
      background: url("../../public/img/bmc.png") left center no-repeat;
    }
    &.export-hiring {
      background: url("../../public/img/hiring.png") left center no-repeat;
    }
    &.export-pl {
      background: url("../../public/img/pl.png") left center no-repeat;
    }
  }
}

.export-circle {
  background: #1d1d1d;
  border: 2px solid $violet;
  height: 44px;
  width: 44px;
  border-radius: 100%;
  padding: 10px 12px;
  z-index: 35;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.slider {
  width: 100%;
  display: block;
  vertical-align: middle;
}

.slider-max-input {
  width: 100%;
  background: none;
  color: #999999;
  border: 1px solid rgba(138, 138, 138, 0.5);
  font-size: 1rem;
  text-align: center;
  border-radius: 15px;
}

.slider-max-input:focus {
  background: $grey-button-background;
  color: $blue;
}

.slider-label {
  user-select: none;
}

.react-tooltip-lite {
  background: #333;
  color: white;
}

.react-tooltip-lite-up-arrow {
  border-top: 10px solid #333;
}

.react-tooltip-lite-down-arrow {
  border-bottom: 10px solid #333;
}

.react-tooltip-lite-right-arrow {
  border-right: 10px solid #333;
}

.react-tooltip-lite-left-arrow {
  border-left: 10px solid #333;
}

.scenario {
  height: 100%;
}

@media (max-width: 600px) {
  h1 {
    font-size: 1.5rem;
  }
}

.bg-img-top {
  position: absolute;
  top: 0;
  width: 100%;
}

.bg-img-right-corner {
  position: absolute;
  right: 0;
  bottom: 0;
}

input {
  background: none;
  border: none;
  padding: 7px 14px;
  font-size: 16px;
}

.full-width-input {
  display: block;
  margin: 20px auto;
  width: 100%;
  border-bottom: 1px solid $grey-lighter;
  border-radius: 0;
  color: $white;
  font-weight: bold;
  padding-left: 0;
  padding-right: 0;
}

.full-width-input::placeholder {
  color: $grey-lighter;
  font-weight: normal;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: $blue-link;
}

.fa-info-circle {
  color: darkgrey;
}

.modal {
  z-index: 999;
}

.modal-content {
  width: 100%;
  padding: 40px 48px 48px 48px;
  max-width: 434px;
  min-height: 300px;
}

.exporter-subtitle {
  max-width: 273px;
  margin: auto;
}

.export-form-button {
  padding-left: 75px;
  padding-right: 75px;
}

.advice-container {
  margin-left: -11%;
  width: 100%;
}

.advice-list {
  height: auto;
  overflow-y: scroll;
  max-height: calc(95vh - 600px);
}

.notification-dot {
  display: inline-block;
  vertical-align: top;
  margin-top: 17px;
  margin-right: 3.5%;
  width: 2.5%;
}

.advice-text {
  background-color: $grey-button-background;
  border-radius: 16px;
  padding: 12px 15px;
  display: inline-block;
  width: 93%;
}

.chart-legend {
  text-align: center;
}

.link {
  color: $blue-link;
  text-decoration: underline;
  font-weight: bold;
}

.ignition-link {
  margin-top: -12px;
}

.legend-color {
  height: 13px;
  width: 13px;
  margin-right: 10px;
  display: inline-block;
}

.has-bg-blue {
  background-color: $blue;
  color: $grey-orange-button;
}

.has-bg-orange {
  background-color: $orange;
  color: $grey-orange-button;
}

.ignition-logo-container {
  padding: 10px 20px 30px 20px;
  border-radius: 23px;
}

.ignition-cta-container {
  margin-top: -106px;
  padding: 10px 20px 30px 20px;
  margin-left: 2.5%;
  z-index: 1;
  position: relative;
}

.scenario-container {
  background: $grey-scenario-bg;
  border-radius: 20px;
  overflow: hidden;
  max-width: 250px;
  margin: auto;
}

.scenario-content {
  background: #1d1d1d;
  border-radius: 0 0 20px 20px;
  border: 2px solid #1dc0fe;
  padding: 8px 10px;
  margin-top: -2px;
}

.scenario-title {
  color: $grey-scenario-bg;
  padding: 5px 0;
}

.scenario-summary {
  width: 100%;
  vertical-align: top;
  text-align: center;
}

.scenario-icon-container {
  width: 50%;
  text-align: center;
  display: none;
}

.scenario-metric {
  background: $grey-button-background;
  border-radius: 7px;
  padding: 10px 0;
  text-align: center;
}

.share-buttons-container {
  max-width: 200px;
  margin: auto;
  padding: 0;
}

.share-button {
  background: $grey-button-background;
  border-radius: 7px;
  padding: 10px 15px;
  display: inline-block;
}

.share-icon {
  color: $white;
}

.profit-string {
  padding-bottom: 5px;
}

.scenario-destiny {
  border-bottom: 1px solid;
  border-top: 1px solid;
  padding: 9px 0;
  text-align: left;
  min-height: 137px;
}

.no-wrap {
  white-space: nowrap;
}

.rounded-box {
  border-radius: 10px;
  background: $grey-scenario-bg;
}

.rc-slider-track {
  background-image: $slider-gradient;
}

// Scrollbar styling

.advice-list::-webkit-scrollbar {
  width: 10px;
}

.has-text-grey-label {
  color: $grey-label-text;
}

.advice-list::-webkit-scrollbar-track {
  background: $grey-button-background;
  border-radius: 24px;
}

.advice-list::-webkit-scrollbar-thumb {
  background: $orange;
  border-radius: 24px;
}

// Checkbox styling
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 13px;
    height: 13px;
    background: transparent;
    border-radius: 4px;
    border: 1px solid $blue;
    vertical-align: unset;
  }

  // Box hover
  &:hover + label:before {
    background: $blue;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: $blue;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 8px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

@media screen and (max-width: 440px) {
  .cumulative-checkbox {
    display: block;
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .sticky-top {
    z-index: 999;
    position: fixed;
    top: 0;
    background: linear-gradient(-180deg, $gradient-top 88%, transparent 100%);
    margin-left: -0.75rem;
    width: 100vw;
  }

  .controls-container {
    margin-bottom: 65px;
    margin-top: 310px;
  }
}

@media screen and (max-width: 767px) {
  .no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .no-margin-mobile {
    margin: 0 !important;
  }

  .app-bg {
    background: $gradient-top;
  }

  .chart-container {
    padding: 0 !important;
  }

  .footer-bg {
    position: absolute;
    width: 100%;
    height: auto;
  }

  .footer-container {
    height: auto;
    padding-top: 0;
    position: relative;
    top: 0;
    margin-top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding-bottom: 2rem;
  }

  .footer-logo {
    float: none !important;
  }

  .ignition-cta-container {
    text-align: center;
    margin-left: 0;
    float: none !important;
  }

  .text-align-center-mobile {
    text-align: center;
  }

  .revenue {
    padding-top: 1rem;
  }

  div#_hj-f5b2a1eb-9b07_feedback {
    display: none;
  }

  .ml-3-mobile {
    margin-left: 1rem;
  }
}

@media screen and (min-width: 1088px) {
  .container {
    max-width: 9999px !important;
    width: 95% !important;
  }

  // Calculating the height of the chart to size the container
  .chart-wrapper {
    height: calc(((((100vw - 72px) * 0.75 - 21px) * 0.75 - 52px) * 0.5) + 91px);
  }
}

@media screen and (min-width: 1440px) {
  .container {
    max-width: 9999px !important;
    width: 95% !important;
  }

  .scenario-icon-container {
    display: inline-block;
  }

  .scenario-summary {
    width: 50%;
    padding-left: 17px;
  }

  .is-block-small-desktop {
    display: block !important;
  }
}

@media screen and (min-width: 1700px) {
  .is-one-fifth-huge {
    width: 20% !important;
  }
}
