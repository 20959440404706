// Colors
$blue-input: #6ac5e2;
$blue: #1dc0fe;
$blue-link: #0050ff;
$gradient-bottom: #181818;
$gradient-top: #090909;
$grey-button-background: #292929;

$grey-chart-background: rgba(216, 216, 216, 0.02);
$grey-chart-line: #979797;
$grey-chart-zero-line: #d8d2d2;

$grey-darker: #000000;
$grey-label-text: #414447;
$grey-light: #8a8a8a;
$grey-button: $grey-light;
$grey-lighter: #bab8bb;
$grey-orange-button: $grey-button-background;

$grey-scenario-bg: #1d1d1d;
$grey-slider-labels: $grey-label-text;
$orange: #ff951a;
$purple: #4f1e9a;
$violet: #845ef7;
$slider-gradient: linear-gradient(-90deg, #d2e7ed 0%, #6edeff 100%);
$slider-rail: #0f0f0f;
$white: #ffffff;

// Typography
$family-primary: "Patron", sans-serif;
$family-secondary: "LaborUnion-Regular", serif;
$text: $grey-light;
$text-light: $grey-lighter;
$white-ter: $grey-lighter;

$crunchable-violet: #7048e8;
