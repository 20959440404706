// Font Faces come here :-)
@font-face {
  font-family: "Patron";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/PatronWEB-Bold.woff2") format("woff2"),
    url("./fonts/PatronWEB-Bold.woff") format("woff");
}

@font-face {
  font-family: "Patron";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/PatronWEB-Regular.woff2") format("woff2"),
    url("./fonts/PatronWEB-Regular.woff") format("woff");
}

@font-face {
  font-family: "LaborUnion-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/LaborUnion-Regular.otf") format("opentype");
}

// Import Space Mono font
@import url('https://fonts.googleapis.com/css?family=Space+Mono');

@mixin button-text($font-size: 1rem) {
  font-family: $family-primary;
  font-weight: bold;
  font-size: $font-size;
  color: $grey-button;
}
